.mini-loader-holder {
  background-color: #1a1226;
}
.mini-loader {
  position: absolute;
  left: 50%;
  top: 50%;
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.mini-loader  .loader{
width: 300px;
height: 100px;
background: #4a8ff7;
display: flex;
flex-wrap: wrap;
align-items: center;
justify-content: center;
border-radius: 8px;
}
.mini-loader  .loader span{
display: inline-block;
width: 40px;
height: 40px;
background: #FFF;
border-radius: 50%;
margin: 0 60px 0px 0;
box-shadow: 60px 0px 0 #FFF;
position: relative;
}
.mini-loader  .loader span:after{
content: '';
width: 100%;
height: 100%;
background: #000;
position: absolute;
left: 0;
top: 0;
border-radius: 50%;
box-shadow: 60px 0px 0 #000;
animation: closeEyes 15s linear infinite;
}
@keyframes closeEyes{
0%,2%,4%,49%,51%,50%,79%,81%, 100%{
  height: 25%;
}
3%,50%, 80%{
  height: 100%;
}
}
.loader span:hover:after{
height: 100%;
transition: 0.4s;
}
.mini-loader  .loader span::before{
 content: '';
height: 20px;
width: 20px;
border-radius: 50%;
background: #000;
position: absolute;
bottom: 6px;
left: 3px;
box-shadow: 62px 0 0 #000;
animation: moveEyes 2s linear alternate infinite;
}
@keyframes moveEyes{
to{
  left: 12px;
}
}
.mini-loader  p{
flex: 0 0 100%;
color: #FFF;
font-family: sans-serif;
text-transform: uppercase;
font-weight: 600;
text-align: center;
letter-spacing: 3px;
 -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(255,255,255,0.1);
background-image: linear-gradient( to left, transparent, #fff,#fff ,#fff, transparent);
background-repeat: no-repeat;
background-size: 200%;
background-position: -100%;
animation: loadingText 2s linear alternate infinite;
}
@keyframes loadingText{
from{
  background-position-x: 200%;
}
to{
  background-position-x: -100%;
}
}
