#header{padding: 0;position: static !important;}
#header .navbar {padding: 40px 0;}
#header .nav-wrapper {width: 100%;z-index: 1;top: 0;}
#header .navbar-brand {color: #fff;padding: 18px 0 0;text-align: end;display: initial;cursor: pointer;}
#header .navbar-brand img{width: 100px;}
#header .logo {font-family: 'poppins semi-bold';font-size: 27px;font-weight: 600;line-height: 11px;}
#header .logo-span {font-family: 'poppins medium';font-size: 12px;font-weight: 500;opacity: .45;}
#header  .navbar-expand-lg .navbar-collapse {justify-content: end;align-items: center;}
#header .nav-wrapper .nav-link {color: #fff!important;cursor: pointer;font-family: 'poppins medium';font-size: 16px;font-weight: 500;margin: 0px 50px 0 0;padding: 0;opacity: .45;position: relative; transition: all ease-in-out 0.3s;}
#header .nav-wrapper .nav-link.active{opacity: 1;}
#header .nav-wrapper .nav-link:hover {opacity: 1;}
#header .nav-wrapper .login-btn {background: linear-gradient(90deg,#5367c7,#1f2b66);border: none;border-radius:10px;color: #fff;font-family: 'poppins semi-bold';font-size: 12px;font-weight: 600;line-height: 5px;padding: 11px 19px 12px;position: relative; transition: all ease-in-out 0.3s;margin: 0 0 0 20px;height: 40px; }
#header button.login-btn:hover {background: #5367c7;}
#header .navbar .wallet-menu .dropdown-toggle {background-color: transparent;border: none;padding: 0;background-image: none;}
#header .navbar .wallet-menu .dropdown-toggle:after {bottom: 0;margin-left: 0;position: absolute;width: 5px;height: 5px;border: solid #fff;border-width: 0 1px 1px 0;-o-transform: rotate(45deg);-ms-transform: rotate(45deg);-moz-transform: rotate(45deg);-webkit-transform: rotate(45deg);transform: rotate(45deg);}
#header .navbar .wallet-menu .dropdown-toggle img {width: 30px;height: 30px;}
#header .navbar .add-button {color: #fff;font-family: 'poppins medium';font-size: 34px;font-weight: 500;line-height: 14px;padding-left: 5px;}
#header .nav-menu{display: flex;align-items: center;}
/* Dropdown Button */
#header .dropbtn {color: white;background-color: transparent;border: none;} 
/* The container <div> - needed to position the dropdown content */
#header .dropdown {position: relative;display: inline-block;}/* Dropdown Content (Hidden by Default) */
#header .dropdown-content {width: 100%; display: none;position: absolute;background-color: #f1f1f1;min-width: 210px;box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);z-index: 1;}/* Links inside the dropdown */
#header .dropdown-content a {color: white !important;padding: 12px 16px;text-decoration: none;display: block}
#header .dropdown:active  .dropdown-content a:active{background-color: #231532 !important;color: #FF9F31;}
#header .dropdown:active{color: #FF9F31;}
#header .dropdown:hover .dropdown-content{background-color: #433958;color: #fff;display: block;border-radius: 10px;padding: 0;overflow: hidden;}
#header .navbar .dropdown-menu .dropdown-item {padding: 5px 10px;color: #fff;}
#header .navbar .dropdown-menu .dropdown-item:hover,
#header .navbar .dropdown-menu .dropdown-item:active,
#header .navbar .dropdown-menu .dropdown-item.active,
/* #header .navbar .dropdown-menu .dropdown-item:focus{background-color: #dc3545;} */
/* Change color of dropdown links on hover */
#header .dropdown-content a:hover {color: #5367c7 !important;}
#header .dropdown:hover .dropdown-content {display: block; position: absolute !important;}
#header .dropdown:hover .info-menu {display: block;}
#header .header-search-area .dropdown{width: 100%;}
#header button{margin: 10px 10px;}
.custom-currency-dropdown button{padding: unset;}
/* Change the background color of the dropdown button when the dropdown content is shown */
#header .dropdown:hover .dropbtn {color: #fff;}
#header .dropdown-content:hover {background: #433958;}
#header .dropdown-content a.active{background: #433958;color: #FF9F31;}
#header .dropdown:focus-visible{outline: none;}
#header .dropbtn:focus-visible{outline: none;}
#header .dropbtn:focus{outline: none;}
#header .dropbtn.active{color: #FF9F31;}
#header .info{width: 100%;}
#header .filter-img{width: 30px !important;height: 30px !important;object-fit: cover;}
#header .navbar li.active .dropbtn{color:#FF9F31;}
#header .bars-icon {color: #5367c7;font-size: 25px;}
#header button:focus{outline: none !important;}
#header .top-icon-1{width: 40px;height: 40px;border-radius: 8px;display: flex;justify-content: center;align-items: center;background-color: 
    #1f2b66 ;margin: 0 0 0 20px;border: none;}
#header .top-icon-2{width: 40px;height: 40px;border-radius: 8px;display: flex;justify-content: center;align-items: center;background-color:  #1f2b66 ;background-image: none !important;padding: 0; border: none;margin: 0 0 0 20px;}
#header .top-icon-2::after{display: none;}
#header .top-icon-1 img {width: 15px;height: 15px;}
#header .top-icon-2 img {width: 15px;height: 15px;}
#header .create-button{border-radius: 8px;background-color: #1f2b66 ;padding: 3px 15px;font-size: 12px;font-family: 'poppins semi-bold';font-weight: 700; color: #fff; margin: 0 0 0 20px;border: none;}
#header .explore-button{border-radius: 8px;background-color: #1f2b66 ;padding: 3px 15px;font-size: 12px;font-family: 'poppins semi-bold';font-weight: 700; color: #fff;border: none;margin: 0 0 0 20px;}
#header .search-bar .form-control {position: relative; color: #ffff;width: 323px;border-radius: 10px;border: 2px solid #827d89; background-color: transparent !important;font-size: 12px;padding: 8px 12px 8px 40px;height: 100%;}
#header .search-bar .form-control::placeholder{color: #ffffff;}
#header .search-bar {position: relative; margin: 0 0 0 20px;}
#header .search-bar .search-icon {z-index: 999; position: absolute;left: 18px;top: 50%;transform: translateY(-50%);font-size: 12px;color: #ffffff;cursor: pointer;}
#header .menu-btn {background-color: transparent;color: #fff;border: none;padding: 0;font-size: 30px;}
#header .wallet-menu.dropdown {margin: 0 0 0 20px;}
.fund-close-btn{background:#dc3545;}
.funds-privider a{transition: all .3s ease-in-out; margin: 0px auto; display: block; background-image: none;background: linear-gradient(90deg,#7f8bc6,#5367c7); padding: 20px 40px;font-size: 25px;margin-bottom: 20px;width:100%;}
.funds-privider a:hover{background-color: #0d153b;background-image: none;}
.funds-title{color: #fff;}
/*menu modal*/
.menu-modal .modal-dialog {margin: 30px 30px 30px auto;}
.menu-modal .modal-dialog .modal-content {background-color: #0d153b;border-radius: 20px;max-height: 700px;min-height: 200px; overflow: hidden;}
.menu-modal .modal-dialog .modal-content .modal-cross-icon {position: absolute;right: 30px;top: 10px;font-size: 30px;z-index: 999; cursor: pointer;}
.modal-backdrop {opacity: .5;background-color: rgba(0, 0, 0, 0.9) !important;}
.menu-modal .nav-menu {margin: 0 0 5px;display: inline-block;}
.menu-modal .nav-menu .nav-link {color: #fff !important;cursor: pointer;font-family: 'poppins medium';font-size: 31px;font-weight: 500;margin: 0;padding: 0;position: relative; transition: all ease-in-out 0.3s;display: inline-block;white-space: nowrap;}
.menu-modal .nav-menu .nav-link:hover {color: #5367c7 !important;}
.menu-modal .modal-body{padding:50px; display: block;overflow: auto;}
.menu-modal .gradient-menu .gradient-link-1{font-size: 21px;font-family: 'poppins semi-bold';font-weight: 700;display: block;margin: 0 0 18px;background: -webkit-linear-gradient(272deg, #5367c7, #cbd2f0);-webkit-background-clip: text;-webkit-text-fill-color: transparent;}
.menu-modal .gradient-menu .gradient-link-2{font-size: 21px;font-family: 'poppins semi-bold';font-weight: 700;display: block;margin: 0 0 18px;background: -webkit-linear-gradient(272deg, #5367c7, #cbd2f0);-webkit-background-clip: text;-webkit-text-fill-color: transparent;}
.menu-modal .gradient-menu .gradient-link-3{font-size: 21px;font-family: 'poppins semi-bold';font-weight: 700;display: block;margin: 0 0 18px;background: -webkit-linear-gradient(272deg, #5367c7, #cbd2f0);-webkit-background-clip: text;-webkit-text-fill-color: transparent;}
.menu-modal .gradient-menu {margin: 0 0 25px;display: block;list-style: none;padding: 0;}
.menu-modal .form-control {height: 45px;background: transparent;border: 1px solid #fff;color: #fff;border-radius: 10px;font-size: 12px;font-family: 'poppins regular';font-weight: 400;padding: 0 20px;}
.menu-modal .form-control::placeholder{color: #fff;}
.menu-modal .gradient-menu li a:hover{-webkit-background-clip: text;
    -webkit-text-fill-color: #fff;}
/*popup menu modal*/
.chat-modal .chat-modal{padding:10px 0px !important;}
.chat-modal .your-messages{color: #fff;font-weight: bold;font-size: 19px;font-family: 'poppins semi-bold';}
.chat-modal .view-all-messages{color:#5367c7;font-size: 16px;font-family: 'poppins semi-bold';}
.custom-chat-header{border-bottom: 1px solid #403d63;}
.contact-container{padding: 0px !important;}
.menu-modal .username .username-img{display: block;width: 45px;height: 45px; border-radius: 50%; overflow: hidden;}
.menu-modal .username .username-img img{width: 100%; height: 100%; object-fit: cover;}
.menu-modal .username .username-name{margin-left: 20px; width: calc(100% - 65px);font-size: 30px;font-family: 'poppins medium';font-weight: 500;}
.menu-modal .metamask-section .meta-head{font-size: 23px; line-height: 27px;font-family: 'poppins medium';font-weight: 500 ;color: #cdd3d8;margin: 0 0 30px; display: block;}
.menu-modal .metamask-section .meta-sec {border: 1px solid #5a4b74; border-radius: 10px;padding: 30px 25px; margin: 0 0 30px;}
.menu-modal .metamask-section .meta-sec .meta-sec-in{margin: 0 0 28px;}
.menu-modal .metamask-section .meta-sec .meta-data .meta-img{width: 50px; height: 50px; overflow: hidden;}
.menu-modal .metamask-section .meta-sec .meta-data .meta-img img{width: 100%; height: 100%;object-fit: contain;}
.menu-modal .metamask-section .meta-sec .meta-data .meta-content{margin-left: 20px; width: calc(100% - 70px);}
.menu-modal .metamask-section .meta-sec .meta-data .meta-content .eth-tag{font-size: 18px; line-height: 22px;font-family: 'poppins regular';font-weight: 400 ;color: #5a4b74;position: relative; display: block;margin: 0 0 10px;}
.menu-modal .metamask-section .meta-sec .meta-data .meta-content .eth-tag .greenDot{font-size: 42px; color: greenyellow !important;}
.menu-modal .metamask-section .meta-sec .meta-data .meta-content .waalet-address{font-size: 18px; line-height: 22px;font-family: 'poppins medium';font-weight: 500 ;color: #fff;}
.menu-modal .funds-tag{font-size: 17px;font-weight: 500;font-family: 'poppins medium';line-height: 21px;display: block;margin: 0 0 15px;}
.menu-modal .wallet-address{font-size: 17px;font-weight: 500;font-family: 'poppins medium';line-height: 21px;display: block;}
.menu-modal .metabutton{font-size: 19px;font-family: 'poppins medium';font-weight: 500;background: #1f2b66 ;border: none;padding: 15px 20px;border-radius: 10px;color: #fff;transition: all ease-in-out 0.3s;}
.menu-modal .metabutton:hover{background: linear-gradient(90deg, #5367c7 60%, #5367c7 );}
.menu-modal .metabutton:focus{outline: none !important;}
.menu-modal .meta-buttons button{padding: 15px 30px !important;}
.menu-modal .metamask-section .meta-sec-1 {border: 1px solid #5a4b74; border-radius: 10px;padding: 10px 15px; margin: 0 0 15px;position: relative;}
.menu-modal .metamask-section .meta-sec-1 .usd-tag{font-size: 13px;font-family: 'poppins medium';font-weight: 500; display: block; margin: 0 0 15px;}
.menu-modal .metamask-section .meta-sec-1 .meta-currencies {margin: 0 0 25px;}
.menu-modal .metamask-section .meta-sec-1 .meta-currencies  .meta-c-img {width: 20px;height: 30px;text-align: center;}
.menu-modal .metamask-section .meta-sec-1 .meta-currencies  .meta-c-img img{width: 100%; height: 100%; object-fit: contain;}
.menu-modal .metamask-section .meta-sec-1 .meta-currencies  .meta-c-type{font-size: 19px;font-family: 'poppins medium';font-weight: 500;}
.menu-modal .metamask-section .meta-sec-1 .meta-currencies  .meta-c-price{ font-size: 15px;font-family: 'poppins medium';font-weight: 500; color:#6f5c90;}
/*modes swicth*/
.menu-modal .stitch-modes .switch {position: relative;display: inline-block;width: 60px;height: 34px;}
.menu-modal .stitch-modes .switch input {opacity: 0;width: 0;height: 0;}
.menu-modal .stitch-modes .slider {position: absolute;cursor: pointer;top: 0;left: 0;right: 0;bottom: 0;background-color: #1f1e26;-webkit-transition: .4s;transition: .4s;box-shadow: 0 0 1px #fff;}
.menu-modal .stitch-modes .slider:before {position: absolute;content: "";height: 26px;width: 26px;left: 4px;bottom: 4px;background-color: white;-webkit-transition: .4s;transition: .4s;}
.menu-modal .stitch-modes input:checked + .slider {background-color: #1f1e26;}
.menu-modal .stitch-modes input:focus + .slider {box-shadow: 0 0 1px #1f1e26;}
.menu-modal .stitch-modes input:checked + .slider:before {-webkit-transform: translateX(26px);-ms-transform: translateX(26px);transform: translateX(26px);}
.menu-modal .stitch-modes .slider.round {border-radius: 34px;}
.menu-modal .stitch-modes .slider.round:before {border-radius: 50%;}
.menu-modal .stitch-modes .mode-tag{position: absolute;left: -165px;top: 50%;transform: translateY(-50%);font-size: 25px; font-weight: 700;font-family: 'poppins semi-bold';}
.menu-modal .stitch-modes .slider ~ .dark-mode{display: block;}
.menu-modal .stitch-modes .slider ~ .light-mode{display: none;}
.menu-modal .stitch-modes input:checked + .slider ~ .dark-mode{display: none;}
.menu-modal .stitch-modes input:checked + .slider ~ .light-mode{display: block;}
/**/
.menu-modal.explore-modal .modal-dialog {margin: 30px 426px 30px auto;}
.menu-modal .modal-search-margin {margin: 40px 0 300px;}
/*notification modal*/
.menu-modal.noti-modal .username .noti-tag {font-size: 25px;font-family: 'poppins medium';font-weight: 500;}
.modal-content *::-webkit-scrollbar-thumb,.modal-content::-webkit-scrollbar-thumb{background-color: transparent !important;}
.menu-modal.noti-modal .modal-dialog .modal-content .modal-body .noti-btn button{background: linear-gradient(90deg, #f46894 50%, #f083d6 );color:#fff;font-size: 17px;font-family: 'poppins medium';font-weight: 500;width: 100%;border: none;padding: 22px 10px;border-radius: 5px;}
.menu-modal.noti-modal .modal-dialog .modal-content .modal-body .noti-btn button:hover{background: linear-gradient(90deg, #f083d6 10%, #f46894 );}
.menu-modal.noti-modal .modal-dialog .modal-content .modal-body .notifications{min-height: 440px; margin: 0 0 30px;}
.menu-modal.noti-modal .modal-dialog .modal-content .modal-body .notifications .no-notification{display: flex;justify-content: center;align-items: center;min-height: 440px;}
.menu-modal.noti-modal .modal-dialog .modal-content .modal-body .notifications .no-notification .main-tag{display: block;font-size: 40px;font-family: 'poppins medium';font-weight: 500;margin: 0 0 20px;}
.menu-modal.noti-modal .modal-dialog .modal-content .modal-body .notifications .no-notification .detail-tag{display: block;font-size: 23px;font-family: 'poppins regular';font-weight: 400;color: #6d5d85;}
.menu-modal.noti-modal .modal-dialog .modal-content .modal-body .notifications-data .image{width: 70px; border-radius: 10px; padding: 10px; height: 70px;object-fit: cover;border: 2px solid #8d8d8d;}
.menu-modal.noti-modal .modal-dialog .modal-content .modal-body .notifications-data .image img{width: 100%; height: 100%;}
.menu-modal.noti-modal .modal-dialog .modal-content .modal-body  .notifications-data .content h2{max-width: 250px; white-space: nowrap; overflow: hidden;text-overflow: ellipsis; color: #fff;font-size: 15px; margin: 0 0 2px 0;}
.menu-modal.noti-modal .modal-dialog .modal-content .modal-body  .notifications-data .content {margin-bottom: 30px; padding-left: 30px;font-family: 'poppins medium';}
.menu-modal.noti-modal .modal-dialog .modal-content .modal-body  .notifications-data .content h6{font-size: 15px;margin: 0 0 2px 0;color: #8d8d8d;font-weight: 700;}
.menu-modal.noti-modal .modal-dialog .modal-content .modal-body  .notifications-data .content p{margin: 0;font-size: 12px;color: #8d8d8d;font-weight: 700;}
.dropdown-category-heading{font-size: 20px;padding: 20px;position: relative;letter-spacing: 2px;background: -webkit-linear-gradient(360deg, #5367c7, #cbd2f0);-webkit-background-clip: text;-webkit-text-fill-color: transparent;font-weight: 400;font-family: 'poppins regular';margin-top: 0;}
.custom-dropdown-menu-1{max-height: 420px;overflow-x: hidden;overflow-y: auto;z-index: 2000;position: relative;}
.custom-dropdown-menu-2{max-height: 460px;overflow-x: hidden;overflow-y: auto;}
.custom-dropdown-menu-1 a{font-size: 14px !important;}
.custom-dropdown-menu-1 .search-close-icon{cursor: pointer; position: absolute;right: 5%;top: 2%; z-index: 9999; font-size: 18px;color: rgb(255, 255, 255);font-weight: bold;}
.custom-dropdown-menu-1 .search-close-icon:hover{color: red;}
.nav-link:hover{color: red;}
#header .explore-button:hover,#header .create-button:hover,#header .top-icon-2:hover,#header .top-icon-1:hover{background: #5367c7;}
.notification-icon{position: relative;}
.notifiction-badge{position: absolute;top: 0;right: 0;width: 20px;height: 20px;background: linear-gradient(90deg,#fd5492,#f86f64);color: #fff;display: flex;align-items: center;justify-content: center;font-size: 8px;    top: -12px;right: -12px;border-radius: 50px;}
.header-rest .dropdown img {width: 20px;}
.quick-wallet-placeholder{position: relative;height: 300px;background-color: transparent;margin-bottom: 20px;}
.chat-modal-laceholder{position: relative;height: 100px;background-color: transparent;margin-bottom: 20px;}
.add-header-menu span:hover{color: #5367c7;}
/**/
#header .login-btn .connect-wallet-icon{display: none;}
#header .login-btn .connect-wallet-tag{display: block;}
/*responsive*/
@media only screen and (max-width:1399px){
    #header .nav-wrapper .nav-link { margin: 0px 35px 0 0;}
    .menu-modal .nav-menu .nav-link {font-size: 30px;}
    .menu-modal.explore-modal .modal-dialog { margin: 30px 30px 30px auto;}
    #header .create-button{padding: 5px 15px;}
    #header .explore-button{padding: 5px 15px;}
    #header .menu-btn {margin: 0px 0 0 15px;}
    #header .wallet-menu.dropdown {margin: 0 0 0 15px;}
    #header .nav-wrapper .login-btn {margin: 0 0 0 15px;}
    #header .top-icon-1 {margin: 0 0 0 15px;}
    #header .top-icon-2 {margin: 0 0 0 15px;}
    #header .create-button {margin: 0 0 0 15px;}
    #header .explore-button {margin: 0 0 0 15px;}
    #header .search-bar {margin: 0 0 0 15px;}
}
@media only screen and (max-width:1199px){
    #header .navbar .dropdown-menu .dropdown-item{font-size: 12px;}
    .custom-dropdown-menu-1{left: 0 !important;}
    .funds-privider a{padding: 30px 30px;font-size: 20px;}
    .menu-modal .modal-body{height: unset;}
    #header .nav-wrapper .nav-link { margin: 0px 20px 0 0;}
    .menu-modal .nav-menu .nav-link {font-size: 26px;}
    /*noti modal*/
    .menu-modal.noti-modal .username .noti-tag {font-size: 35px;}
    .menu-modal.noti-modal .modal-dialog .modal-content .modal-body .notifications .no-notification .main-tag {font-size: 35px;}
    .menu-modal.noti-modal .modal-dialog .modal-content .modal-body .notifications .no-notification .detail-tag {font-size: 21px;}
    /*header*/
    #header .menu-btn {margin: 0px 0 0 10px;}
    #header .wallet-menu.dropdown {margin: 0 0 0 10px;}
    #header .nav-wrapper .login-btn {margin: 0 0 0 10px;}
    #header .top-icon-1 {margin: 0 0 0 10px;}
    #header .top-icon-2 {margin: 0 0 0 10px;}
    #header .create-button {margin: 0 0 0 10px;}
    #header .explore-button {margin: 0 0 0 10px;}
    #header .search-bar {margin: 0 0 0 10px;}
    #header .search-bar .form-control {width: 240px;}
    #header .navbar .dropdown-menu {left: -80px;}
    /**/
    /**/
    #header .login-btn .connect-wallet-icon{display: block;}
    #header .login-btn .connect-wallet-tag{display: none;}
    #header .nav-wrapper .login-btn {font-size: 14px;padding: 11px 13px 12px;}
}
@media only screen and (max-width:991px){
    .funds-privider a{padding: 20px 20px;font-size: 15px;}
    #header .navbar-brand {margin-left: 6%; padding: 0px 0 0;}
    #header .navbar {padding: 15px 0;}
    #header .navbar-toggler {padding: 0;border: none;right: 5%;top: 50%;}
    #header .navbar-collapse {top: calc(100% + 0px) !important;z-index: 99999;background-color: #0d153b;}
    #header .nav-wrapper .nav-link {margin: 0px 0px 0 0;padding: 10px;}
    #header .header-rest {margin-right: 4%;}
    .menu-modal .nav-menu .nav-link {font-size: 24px;}
    .menu-modal .gradient-menu .gradient-link {font-size: 19px;}
    /*noti modal*/
    .menu-modal.noti-modal .username .noti-tag {font-size: 30px;}
    .menu-modal.noti-modal .modal-dialog .modal-content .modal-body .notifications .no-notification .main-tag {font-size: 30px;}
    .menu-modal.noti-modal .modal-dialog .modal-content .modal-body .notifications .no-notification .detail-tag {font-size: 19px;}
    .header-rest{justify-content: center;flex-wrap: wrap;}
   /**/
   #header .navbar-brand {display: inline;}
   #header .navbar-toggler {top: 40%;}
}
@media only screen and (max-width:768px){
    .menu-modal .modal-body{height: unset;}
}
@media only screen and (max-width:767px){
    .menu-modal.noti-modal .modal-dialog .modal-content .modal-body  .notifications-data .content h2{font-size: 12px !important;}
    .header-rest{display: block !important;}
    /**/
    #header .navbar-toggler {top: 44%;}
    #header .nav-wrapper .login-btn {display: inline-block !important;}
}
@media only screen and (max-width:575px){
    .search-rest{flex-wrap: wrap;}
    .custom-dropdown-menu-1{height: 340px;}
    .dropdown-item{font-size: 12px !important;}
    /* .custom-dropdown-menu-1{min-width: 282px;} */
    #header .header-search-area .dropdown-menu{left: unset;}
    /* #header button.login-btn:after{position: absolute;content: "...";width: 5px;height: 20px;} */
    .funds-privider a{padding: 15px 18px;font-size: 12px;}
    .menu-modal .modal-body{padding: 20px;height: unset;}
    .menu-modal .modal-dialog .modal-content {padding: unset;}
    .menu-modal.noti-modal .modal-dialog .modal-content .modal-body  .notifications-data .content h2{font-size: 18px !important;}
    .menu-modal.noti-modal .modal-dialog .modal-content .modal-body  .notifications-data .content h6{font-size: 16px;}
    .menu-modal.noti-modal .modal-dialog .modal-content .modal-body  .notifications-data .content p{font-size: 12px;}
    .menu-modal.noti-modal .modal-dialog .modal-content .modal-body .notifications-data .image{height: 50px;width: 50px;}
    .menu-modal.noti-modal .modal-dialog .modal-content .modal-body  .notifications-data .content h2{font-size: 12px;}
    .menu-modal.noti-modal .modal-dialog .modal-content .modal-body  .notifications-data .content h6{font-size: 12px;}
    #header .logo {font-size: 19px;}
    #header .nav-wrapper .wallet-dropdown{position: absolute;right: 6%;top: 100px;}
    #header button { margin: 0;}
    #header .nav-wrapper .wallet-dropdown .login-btn{position: static;}
    /* #header .nav-wrapper .login-btn {position: absolute;right: 5%;top: 100px;} */
    #header .navbar-brand {margin-left: 0%; padding: 22px 0 0;}
    .menu-modal .modal-dialog {margin: 30px 10px 30px 10px;}
    .menu-modal .metabutton {font-size: 18px;}
    .menu-modal .meta-buttons button {padding: 15px 20px !important;}
    .menu-modal .metamask-section .meta-sec-1 .meta-currencies .meta-c-type {font-size: 16px;}
    .menu-modal .stitch-modes .mode-tag {left: -145px;font-size: 20px;}
    .menu-modal.explore-modal .modal-dialog {margin: 30px 10px 30px 10px;}
    /*noti modal*/
    .menu-modal.noti-modal .username .noti-tag {font-size: 25px;}
    .menu-modal.noti-modal .modal-dialog .modal-content .modal-body .notifications .no-notification .main-tag {font-size: 25px;}
    .menu-modal.noti-modal .modal-dialog .modal-content .modal-body .notifications .no-notification .detail-tag {font-size: 17px;}
    .menu-modal.noti-modal .modal-dialog .modal-content .modal-body .noti-btn button {font-size: 15px;}
    #header .header-search-area{flex-direction: column; width: auto; right: 20px; left: 20px;}
    #header .search-bar {margin: 0 0 10px 0px;width: 100%;}
    #header .search-bar .form-control {width: 100%; }
   
}
@media only screen and (max-width:479px){
    #header .nav-wrapper .login-btn {position: static;right: 0;top: 0;}
    #header .nav-wrapper .wallet-dropdown {position: static;right: 0%;top: 0px;}
    #header .header-rest {margin-right: 0;/* position: absolute;top: 100px;left: 50%;transform: translateX(-50%);min-width: 230px; */}
    /* .menu-modal .modal-dialog .modal-content .modal-cross-icon {top: 45px;} */
    .menu-modal .nav-menu .nav-link {font-size: 20px;}
    .menu-modal .gradient-menu .gradient-link {font-size: 16px;}
    .menu-modal .modal-dialog .modal-content {padding: 25px 15px 0px;}
    .menu-modal .modal-dialog {margin: 30px 0px 30px 0px;}
    .menu-modal .metabutton {font-size: 12px;padding: 12px 15px;}
    .menu-modal .metamask-section .meta-sec {padding: 30px 10px;}
    .menu-modal .metamask-section .meta-sec .meta-data .meta-content .eth-tag {font-size: 16px;}
    .menu-modal .metamask-section .meta-sec .meta-data .meta-content .eth-tag .greenDot {right: -7px;top: -7px;}
    .menu-modal .metamask-section .meta-sec-1 .meta-currencies .meta-c-type {font-size: 12px;}
    .menu-modal .metamask-section .meta-sec-1 .meta-currencies .meta-c-price {font-size: 12px;}
    .menu-modal .metamask-section .meta-sec-1 .meta-currencies .meta-c-img {width: 16px;height: 20px;}
    .menu-modal .funds-tag {font-size: 14px;}
    .menu-modal .wallet-address{font-size: 14px;}
    .menu-modal .metamask-section .meta-head {font-size: 20px;}
    .menu-modal .stitch-modes .mode-tag {left: -120px;font-size: 17px;}
    .menu-modal .username .username-name {margin-left: 10px;width: calc(100% - 55px);font-size: 20px;}
    .menu-modal .username .username-img {width: 35px;height: 35px;}
    .menu-modal.explore-modal .modal-dialog {margin: 30px 0px 30px 0px;}
    /*noti modal*/
    .menu-modal.noti-modal .username .noti-tag {font-size: 20px;}
    .menu-modal.noti-modal .modal-dialog .modal-content .modal-body .notifications .no-notification .main-tag {font-size: 20px;}
    .menu-modal.noti-modal .modal-dialog .modal-content .modal-body .notifications .no-notification .detail-tag {font-size: 14px;}
    .menu-modal.noti-modal .modal-dialog .modal-content .modal-body .noti-btn button {font-size: 14px;}
    /*header*/
    #header .wallet-address-in{display: none;}
    #header .explore-button {margin: 0 0 0 0px;}
    .menu-modal .modal-dialog .modal-content .modal-cross-icon {font-size: 22px;}
}
@media only screen and (max-width:319px){
    .menu-modal .meta-buttons button {padding: 15px 18px !important;}
    .menu-modal .metamask-section .meta-sec .meta-data .meta-content {margin-left: 10px;width: calc(100% - 50px);}
    .menu-modal .metamask-section .meta-sec .meta-data .meta-img {width: 40px;height: 40px;}
    #header .logo { font-size: 22px;}
    #header .header-search-area { right: 8px;left: 8px;}
}