.chat-container{
   
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    align-items: center;
}

.lppLhK {
    grid-template-rows: 10% 90% 15% !important;
}
.contact{position: relative;}
.chat-moment-area{position: absolute;top: 5%;right: 3%;}
.unread-dot{width: 7px;height: 7px;border-radius: 50px;background-color: #fd5492;}
.chat-maincontainer {
    max-width: 85%;
    margin: 0px auto;
    overflow-y: hidden;
    height: 75vh;
    border: 2px solid #514f6a;
    width: 100%;
    background-color: transparent;
    display: grid;
    grid-template-columns: 25% 75%;
    z-index: 20;
  }
  @media screen and (min-width: 720px) and (max-width: 1080px) {
    /* grid-template-columns: 35% 65%; */
}

.chat-maincontainer > div{border-right: 1px solid #514f6a}

.contact-container .username p{margin: 3px 0 0 0;font-size: 12px;color: rgb(207, 207, 207);  white-space: nowrap; 
    width: 100px; 
    overflow: hidden;
    text-overflow: ellipsis; }
.contact-container .contact{border-bottom: 1px solid #514f6a}
.contact-container .contact:hover{background-color:#626071;}

.chat-maincontainer{position: relative;}


.brand {
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: center;
  }
  .brand img {
    height:2rem;
  }
  .brand h3 {
    color: white;
    text-transform: uppercase;
    font-size: 20px;
  }
  .contacts {
    
    flex-direction: column;
    align-items: center;
    overflow: auto;
    gap: 0.8rem;
    }
    .contacts::-webkit-scrollbar {
        width: 0.2rem;
    }
    .contacts ::-thumb {
        background-color: #ffffff39;
        width: 0.1rem;
        border-radius: 1rem;
      }
    .contact {
      background-color: transparent;
      min-height: 5rem;
      cursor: pointer;
      width: 100%;
      padding: 0px 14px !important;
      display: flex;
      gap: 1rem;
      align-items: center;
      transition: 0.5s ease-in-out;
      
    }

    .contact .avatar {
        padding-left:10px;
        object-fit: cover;
        
    }
    
    .contact .avatar img {
        border-radius: 50px;
        width: 50px;
        height: 50px;
        border: 3px solid #8d8d8d;
        border-radius: 50px;
    }
    
    .username h3{
          color: white;
    }

    .contact.selected {
        background-color: #0d153b;
    }

  .current-user {
    background-color: #0d0d30;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    
    }

    .current-user .avatar img{
        height: 4rem;
        max-inline-size: 100%;
    }


    .username h2{
        color: white;
    }

    @media screen and (min-width: 720px) and (max-width: 1080px) {
        .username h2{
            font-size: 1rem;
        } 
    }

.ChatContainer-container{
    display: grid;
    gap: 0.1rem;
    overflow: hidden;
    @media screen and (min-width: 720px) and (max-width: 1080px) {
        grid-template-rows: 15% 90% 15%;
    }
}
.ChatContainer-container .chat-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2rem;
}
.ChatContainer-container .chat-header .user-details{
    display: flex;
    align-items: center;
    gap: 1rem;
}
.ChatContainer-container .chat-header .user-details .avatar img{
    height: 3rem;
}
.ChatContainer-container .chat-header .user-details .username h3{
    color: white;
}
.ChatContainer-container .chat-messages{
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    overflow: auto;
}
.ChatContainer-container .chat-messages::-webkit-scrollbar{
    width: 0.2rem;
}
.ChatContainer-container .chat-messages::-webkit-scrollbar-thumb{
    background-color: #ffffff39;
    width: 0.1rem;
    border-radius: 1rem;
    
}
.ChatContainer-container .chat-messages .message{
    display: flex;
    align-items: center;
}

.ChatContainer-container .chat-messages .message .content{
    max-width: 100%;
    width: 20%;
    overflow-wrap: break-word;
    padding: 1rem;
    font-size: 1.1rem;
  
    color: #fff;
    @media screen and (min-width: 720px) and (max-width: 1080px) {
        max-width: 70%;
    }
}
.ChatContainer-container .chat-messages .sended{
    justify-content: flex-end;
      
}
.ChatContainer-container .chat-messages .sended .content{
    background-color: #5367c7;
    width: 200px;
    text-align: right;
    border-bottom-left-radius: 15px;
    border-top-left-radius: 15px;
    border-bottom-right-radius: 0px;
    border-top-right-radius: 15px;
}
.ChatContainer-container .chat-messages .recieved{
    justify-content: flex-start;
}
.ChatContainer-container .chat-messages .recieved .content{
    background-color: #fff;
    color: #000;
    border-bottom-left-radius: 15px;
    border-top-left-radius: 0px;
    border-bottom-right-radius: 15px;
    border-top-right-radius: 15px;
}

.custom-chat-border{border-top: 1px solid#514f6a}

.chatinput-container{
    padding-top: 10px !important;
    display: grid;
    align-items: center;
    grid-template-columns: 5% 95%;

    padding: 0 2rem;
    @media screen and (min-width: 720px) and (max-width: 1080px) {
        padding: 0 1rem;
        gap: 1rem;
    }
}
.chatinput-container .button-container{
    display: flex;
    align-items: center;
    color: white;
    gap: 1rem;
}
.chatinput-container .button-container .emoji{
    position: relative;
}
.chatinput-container .button-container .emoji svg{
    font-size: 1.5rem;
    color: #ffff00c8;
    cursor: pointer;
}
.chatinput-container .button-container .emoji .emoji-picker-react{
    position: absolute;
    top: -350px;
    background-color: #080420;
    box-shadow: 0 5px 10px #9a86f3;
    border-color: #9a86f3;
}
.chatinput-container .button-container .emoji .emoji-picker-react .emoji-scroll-wrapper::-webkit-scrollbar{
    background-color: #080420;
    width: 5px;
}
.chatinput-container .button-container .emoji .emoji-picker-react .emoji-scroll-wrapper::-webkit-scrollbar-thumb{
        background-color: #9a86f3;
}
.chatinput-container .button-container .emoji .emoji-picker-react .emoji-categories button{
    filter: contrast(0);
}
.chatinput-container .button-container .emoji .emoji-picker-react .emoji-search{
    background-color: transparent;
        border-color: #9a86f3;
}
.chatinput-container .button-container .emoji .emoji-picker-react .emoji-group:before{
    background-color: #080420;
}
.chatinput-container .input-container{
    width: 100%;
    border-radius: 10px;
    display: flex;
    align-items: center;
    gap: 2rem;
    background-color: #0d153b;
}
.chatinput-container .input-container input{
    width: 90%;
    height: 60%;
    background-color: transparent;
    color: white;
    border: none;
    padding-left: 1rem;
    font-size: 1.2rem;
}
.chatinput-container .input-container input::selection{
    background-color: #9a86f3;
}
.chatinput-container .input-container input:focus{
    outline: none;
}
.chatinput-container .input-container input::placeholder{font-size: 14px;}
.chatinput-container .input-container button{
    padding: 7px 10px;
    border-radius: 10px;
    position: absolute;
    right: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(90deg,#6a78ba,#5367c7);
    border: none;
    @media screen and (min-width: 720px) and (max-width: 1080px) {
        padding: 0.3rem 1rem;
        svg {
            font-size: 1rem;
        }
    }
}
.chatinput-container .input-container{position: relative;}
.chatinput-container .input-container button {right: 12px !important;}
.chatinput-container .input-container button svg{
    display: flex;align-items: center;justify-content: center;
    font-size: 15px;
    transform: rotate(306deg);
    color: white;
}

.welcome-container{
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    flex-direction: column;
}
.welcome-container h1{
    font-size: 30px;
}
.welcome-container img{
    height: 20rem;
}
.welcome-container span{
    color: #4e0eff;
}
.chat-toggle{overflow-x: hidden;overflow-y: auto;}
.contact-container .username h5{margin:0;}
.ChatContainer-container section{padding: 20px 20px !important;}
.ChatContainer-container .chat-messages{min-height: 60vh;}
.chat-time span{font-size: 10px;white-space: nowrap;}
.message.sended .content p,.message.recieved .content p{font-size: 12px;}
.toggle-btn,.responsive-toggle{display: none;}
@media only screen and (max-width:1599px){  .chat-maincontainer{grid-template-columns: 30% 70%;}}

@media only screen and (max-width:1399px){
   
    .ChatContainer-container .chat-messages .message .content {width: 50%;}
    .chat-maincontainer{grid-template-columns: 35% 65%;}
    .contacts .username h5{font-size: 12px;}
    .chat-time span{font-size: 12px;}
    .contact .avatar img{width: 30px;height: 30px;}
    .contacts .contact .avatar{padding-left: 0px;}
    .welcome-container h3{font-size: 25px;}
}
@media only screen and (max-width:1199px){
    .chatinput-container .button-container .emoji{right: 2px;}
    .chat-maincontainer{grid-template-columns: 40% 60%;}
}
@media only screen and (max-width:992px){
    .chat-maincontainer{grid-template-columns: 50% 50%;}
    .ChatContainer-container .chat-messages .message .content {width: 60%;}
    .chatinput-container .button-container .emoji{right: 7px;position: relative;}
    .welcome-container h3{font-size: 17px;}
}
@media only screen and (max-width:767px){
    .contact-container .username p {width: 50px;}
    .chat-maincontainer {grid-template-columns: 100%;position: relative;}
    .toggle-btn{padding: 15px 15px;background-image: none;background-color: #ff79a1; font-size: 14px;display: block;width: 20px;height: 20px;display: flex;align-items: center;justify-content: center;position: absolute;left: 1%;top: 0%;}
    .welcome-container h1{font-size: 25px;}
    .chat-toggle{position: absolute;height: 100%;background-color: #36254f;z-index: 999;display: none;}
    
}
@media only screen and (max-width:575px){.chatinput-container .input-container button {padding: 12px 12px;} 
.chatinput-container .input-container {margin-left: 10px;}
.chatinput-container .input-container input {width: 75%;} 
.chat-time span {
    font-size: 8px;
}
 .welcome-container h1,.welcome-container h3{font-size: 12px;}
  .toggle-btn{top: 0%;}
  .chatinput-container .input-container input::placeholder{font-size: 12px;}
  .ChatContainer-container .chat-messages .message .content {width: 70%;}
  .chatinput-container .input-container button svg{font: 12px sans-serif;}
}

