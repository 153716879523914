.about-sidebar .yellow-text{color: #ffcc00; font-size: 13px; line-height: 17px; font-family: 'poppins semi-bold';font-weight: 700;}
.about-sidebar .follow-section{margin: 0 0 22px;}
.about-sidebar .follow-section .follow-button{width: 106px;}
.about-sidebar .about-head{color: #ffffff; font-size: 17px; line-height: 21px; font-family: 'poppins bold';font-weight: 700;}
.about-sidebar .sidebar-about{margin: 0 0 22px;}
.about-sidebar .sidebar-about .dropdown .dropdown-toggle{background-image: none !important; background-color: transparent !important; border: none; padding: 0;}
.about-sidebar .sidebar-about .dropdown .dropdown-toggle::after{display: none;}
.about-sidebar .sidebar-about .about-like{width: 106px;}
.about-sidebar .about-para{color: #ffffff; font-size: 15px; line-height: 23px; font-family: 'poppins regular';font-weight: 400;margin: 0 0 90px;hyphens: auto;-webkit-hyphens: auto; -o-hyphens: auto; -ms-hyphens: auto; -moz-hyphens: auto;word-break: break-word;}
.about-sidebar .sidebar-values{border-bottom: 1px solid #5e5273; padding: 0 0 40px;margin: 0 0 40px;}
.about-sidebar .sidebar-values .input-group {position: relative;display: -ms-flexbox;display: flex;-ms-flex-wrap: wrap;flex-wrap: wrap;-ms-flex-align: stretch;align-items: stretch;width: 100%;max-width: 200px;}
.about-sidebar .sidebar-values .input-group input{height: 40px;background-color: #0d194d;color: #fff; border-radius: 30px;padding: 0px 40px;border: none;user-select: none;-webkit-user-select: none;-ms-user-select: none;-moz-user-select: none;-o-user-select: none;}
.about-sidebar .sidebar-values .input-group .ethirem-icon {position: absolute;top: 50%;transform: translateY(-50%);left: 15px;z-index: 99;}
.about-sidebar .sidebar-values .input-group button{padding: 10px 10px !important;}
.about-sidebar .sidebar-values .input-group .input-group-append{z-index: 99 !important;}
.about-sidebar .sidebar-links{border-bottom: 1px solid #5e5273; padding: 0 0 40px;margin: 0 0 40px;}
.about-sidebar .sidebar-links .social-links li a {margin: 0 17px 30px;font-size: 30px;}
.about-sidebar .sidebar-links .social-links li .instagram, .about-sidebar .sidebar-links .social-links li .youtube, .about-sidebar .sidebar-links .social-links li .discord, .about-sidebar .sidebar-links .social-links li .twitter, .about-sidebar .sidebar-links .social-links li .medium, .about-sidebar .sidebar-links .social-links li .telegram, .about-sidebar .sidebar-links .social-links li .reddit, .about-sidebar .sidebar-links .social-links li .github{color: #fff !important; opacity: 0.5;cursor: pointer;}
.about-sidebar .sidebar-links .social-links li .instagram:hover{opacity: 1; color: #c32aa3 !important;}
.about-sidebar .sidebar-links .social-links li .youtube:hover{opacity: 1; color: #ff0000 !important;}
.about-sidebar .sidebar-links .social-links li .discord:hover{opacity: 1; color: #5865f2 !important;}
.about-sidebar .sidebar-links .social-links li .twitter:hover{opacity: 1; color: #1da1f2 !important;}
.about-sidebar .sidebar-links .social-links li .medium:hover{opacity: 1; color: #02b875!important;}
.about-sidebar .sidebar-links .social-links li .telegram:hover{opacity: 1; color: #0088cc!important;}
.about-sidebar .sidebar-links .social-links li .reddit:hover{opacity: 1; color:  #ff4500!important;}
.about-sidebar .sidebar-links .social-links li .github:hover{opacity: 1; }
.about-sidebar .sidebar-feed{margin: 0 0 40px;}
.about-sidebar .sidebar-feed .twitter-feed{ width: 100%; height: 420px; border-radius: 10px;}
.sidebar-custom-icons li{ margin:0 20px 0 0 }
.sidebar-custom-icons li svg {font-size: 25px; transition: all .3s ease-in-out;}
.sidebar-custom-icons li svg:hover { color: #f56285; }
/* responsive */
@media only screen and (max-width:1919px){

}
@media only screen and (max-width:1799px){

}
@media only screen and (max-width:1599px){

}
@media only screen and (max-width:1399px){
    .about-sidebar .sidebar-values .input-group {max-width: 144px;}
    .about-sidebar .follow-section .follow-button {width: 80px;}
    .about-sidebar .sidebar-about .about-like {width: 80px;}
    .about-sidebar .yellow-text {font-size: 12px;}
    .about-sidebar .about-para {font-size: 13px;}
    .about-sidebar .sidebar-values .input-group input {font-size: 12px;}
    .about-sidebar .sidebar-links .social-links li a {margin: 0 10px 30px;}
}
@media only screen and (max-width:1199px){
    
}
@media only screen and (max-width:991px){
    .about-sidebar .about-head{font-size: 14px;}
    .about-sidebar .sidebar-values .input-group{margin-left: 5px;}
    .about-sidebar {z-index: 99999;padding: 14px;left: 0;border-radius: 10px;box-shadow: 0 0 5px rgb(119, 105, 143);}
}
@media only screen and (max-width:767px){

}
@media only screen and (max-width:575px){
    .about-sidebar{
        max-width: 100%;
        left: 10px;
        /* right: 10px; */
        z-index: 99999;
        overflow-y: auto;
    }
}
@media only screen and (max-width:479px){
    .about-sidebar .about-head {font-size: 14px;}
}
@media only screen and (max-width:319px){
    .about-sidebar .yellow-text {font-size: 11px;}
    .about-sidebar {padding: 10px;}
}

